import FiniteStateMachine from './FiniteStateMachine'

import JumpState from '../states/JumpState'
import RunState from '../states/RunState'
import WalkState from '../states/WalkState'
import IdleState from '../states/IdleState'
import RollState from '../states/RollState'

class CharacterFSM extends FiniteStateMachine {
  constructor(proxy) {
    super()
    this.proxy = proxy

    this.addState('idle', IdleState)
    this.addState('walk', WalkState)
    this.addState('run', RunState)
    this.addState('jump', JumpState)
    this.addState('roll', RollState)
  }
}

export default CharacterFSM

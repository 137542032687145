import '../styles/index.css'
import * as THREE from 'three'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'

import MouseFollowCharacterController from './movements/MouseFollowCharacterController'
import ThirdPersonCamera from './cameras/ThirdPersonCamera'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'

import RenderPixelatedPass from './pixelate/RenderPixelatedPass'
import PixelatePass from './pixelate/PixelatePass'

export default class Demo {
  constructor() {
    this.parameters = {
      thirdPersonCamera: false,
    }

    // Init model
    this.init()
  }

  init() {
    // this.stats = Stats()
    // document.body.appendChild(this.stats.dom)

    this.time = new THREE.Clock()
    this.previousTime = 0
    this.models = {}

    this.mixers = []
    this.currentAction = null

    this.container = document.getElementById('webgl-container')
    this.width = this.container.offsetWidth
    this.height = this.container.offsetHeight

    // Renderer
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
    })
    this.renderer.shadowMap.enabled = true
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
    this.renderer.toneMapping = THREE.ReinhardToneMapping
    this.renderer.toneMappingExposure = 2.3
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    this.renderer.setSize(this.width, this.height)
    this.facingDown = false

    this.container.appendChild(this.renderer.domElement)

    // Camera
    const fov = 30
    const aspect = this.width / this.height
    const near = 1.0
    const far = 2000.0
    this.camera = new THREE.PerspectiveCamera(fov, aspect, near, far)
    this.camera.position.set(0, 50, 100) // Changed the camera position to be above and slightly behind the model

    // Scene
    this.scene = new THREE.Scene()
    this.scene.background = new THREE.Color(0x00000)
    // this.scene.fog = new THREE.Fog(0xa0a0a0, 100, 800)

    // add post processing
    let screenResolution = new THREE.Vector2(
      window.innerWidth,
      window.innerHeight
    )
    let renderResolution = screenResolution.clone().divideScalar(4)
    renderResolution.x |= 0
    renderResolution.y |= 0
    this.composer = new EffectComposer(this.renderer)
    this.composer.addPass(
      new RenderPixelatedPass(renderResolution, this.scene, this.camera)
    )
    let bloomPass = new UnrealBloomPass(screenResolution, 0.4, 0.1, 0.9)
    this.composer.addPass(bloomPass)
    this.composer.addPass(new PixelatePass(renderResolution))

    // Light
    let light = new THREE.SpotLight(0xffa95c, 4)
    light.position.set(-50, 550, 50)
    light.castShadow = true
    light.shadow.bias = -0.0001
    light.shadow.mapSize.width = 1024 * 4
    light.shadow.mapSize.height = 1024 * 4
    this.scene.add(light)

    let hemiLight = new THREE.HemisphereLight(0xffeeb1, 0x080820, 4)
    this.scene.add(hemiLight)

    // Orbit Controls
    this.orbitControls = new OrbitControls(
      this.camera,
      this.renderer.domElement
    )
    this.orbitControls.enableDamping = true // Uncommented this line to enable damping
    this.orbitControls.target.set(0, 20, 0)
    this.orbitControls.update()

    // Locking the orbit controls
    this.orbitControls.enableZoom = false
    this.orbitControls.enablePan = false
    this.orbitControls.enableRotate = false
   

    // Ground
    // Compute the visible height and width at the plane's distance (100 units here)
    let distance_from_camera_to_plane = 100
    let visible_height =
      2 * Math.tan((fov * Math.PI) / 180 / 2) * distance_from_camera_to_plane
    let visible_width = visible_height * aspect

    const planeGeometry = new THREE.PlaneGeometry(
      visible_width * 20,
      visible_height * 100,
      10,
      10
    )
    this.planeGeometry = planeGeometry

    const planeMaterial = new THREE.MeshStandardMaterial({
      color: 0x808080,
      metalness: 0.95,
      roughness: 0.05,
      transparent: true,
      opacity: 0,
    })

    const plane = new THREE.Mesh(planeGeometry, planeMaterial)
    plane.castShadow = false
    plane.receiveShadow = true
    plane.rotation.x = -Math.PI / 2
    plane.name = 'Floor Plane'

    this.scene.add(plane)
    this.plane = plane

    this.setupResize()
    this.loadAnimatedModel()
    this.render()

    // Add event listener for spawning new cat on click or with spacebar
    window.addEventListener('click', (event) => {
      this.loadAnimatedModel()
    })
    window.addEventListener('keydown', (event) => {
      this.loadAnimatedModel()
    })
    window.addEventListener('touchstart', (event) => {
      this.loadAnimatedModel()
    })
   
  }

  resize() {
    this.width = this.container.offsetWidth
    this.height = this.container.offsetHeight

    this.camera.aspect = this.width / this.height
    this.camera.updateProjectionMatrix()

    this.renderer.setSize(this.width, this.height)
  }

  setupResize() {
    window.addEventListener('resize', this.resize.bind(this), false)
  }

  loadAnimatedModel() {
    // Support for multiple cats
    this.controls = this.controls || []
    const modelPath = '/models/fatcat/'

    // Check if a model has been loaded in the last second to prevent spamming
    const currentTime = new Date().getTime()
    if (this.lastModelLoadTime && currentTime - this.lastModelLoadTime < 500) {
      console.log('You can only load a new model once per half second. Please wait...')
      return
    }

    this.lastModelLoadTime = currentTime

    const newControl = new MouseFollowCharacterController({
      camera: this.camera,
      scene: this.scene,
      path: modelPath,
    })

    this.controls.push(newControl)
    this.thirdPersonCamera = new ThirdPersonCamera({
      camera: this.camera,
      target: newControl,
    })

    // Add collision detection
    this.controls.forEach((control) =>
      control.collisionObjects.push(newControl)
    )

    // every 5 models, scroll to contact
    if (this.controls.length % 5 == 0) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      })
    }
  }

  /**
   * Load any model and play first animation
   * from different path and position
   */
  loadAnimatedModelAndPlay(path, modelFile, animFile, offset) {
    const loader = new FBXLoader()
    loader.setPath(path)

    loader.load(modelFile, (fbx) => {
      fbx.scale.setScalar(1)
      fbx.traverse((child) => {
        child.castShadow = true
      })
      fbx.position.copy(offset)

      const anim = new FBXLoader()
      anim.setPath(path)

      anim.load(animFile, (anim) => {
        const m = new THREE.AnimationMixer(fbx)
        this.mixers.push(m)

        const idle = m.clipAction(anim.animations[0])
        idle.play()
      })

      this.scene.add(fbx)
    })
  }

  modelUpdates(deltaTime) {
    // Animation
    this.mixers?.map((mix) => mix.update(deltaTime))

    // Movements
    this.controls?.forEach((control) => control.update(deltaTime))
  }

  render() {
    // this.stats.begin()

    const elapsedTime = this.time.getElapsedTime()
    const deltaTime = elapsedTime - this.previousTime
    this.previousTime = elapsedTime

    this.modelUpdates(deltaTime)
    this.renderer.render(this.scene, this.camera)
    this.composer.render()

    window.requestAnimationFrame(this.render.bind(this))

    const dateTimeElement = document.getElementById('datetime')

    const now = new Date()
    const dateTimeString = now.toLocaleString() // convert date and time to string

    dateTimeElement.textContent = dateTimeString

    // Get the camera's angle
    const vector = new THREE.Vector3()
    this.camera.getWorldDirection(vector)
    const angle = Math.atan2(vector.x, vector.z)

    // Check if the camera is facing down
    if (angle < -Math.PI / 2) {
      this.facingDown = true
    } else {
      this.facingDown = false
    }
  }
}

// const env = process.env.NODE_ENV
window.addEventListener('DOMContentLoaded', () => {
  const demo = new Demo()
  window.demo = demo
})

document
  .getElementById('contactContainer')
  .addEventListener('click', function () {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    })
  })

import * as THREE from 'three'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

import CharacterFSM from '../fsm/CharacterFSM'

import BasicCharacterControllerInput from './BasicCharacterControllerInput'
import BasicCharacterControllerMouseInput from './BasicCharacterControllerMouseInput'
import BasicCharacterControllerProxy from './BasicCharacterControllerProxy'

import { hideLoader } from '../../utils/loader'

class MouseFollowCharacterController {
  constructor(params) {
    this.params = params // <=> { camera, scene, path }

    this.decceleration = new THREE.Vector3(-0.0005, -0.0001, -5.0)
    this.acceleration = new THREE.Vector3(1.0, 0.25, 50.0)
    this.velocity = new THREE.Vector3(0, 0, 0)
    this._position = new THREE.Vector3()

    this.animations = {}
    this.currentAnimation = null

    this.input = new BasicCharacterControllerInput()
    // this.mouseInput = new BasicCharacterControllerMouseInput()
    // set up mouse tracking
    this.mouseInput = new THREE.Vector2()

    this.collisionObjects = []

    // normalize mouse position from -1 to 1
    document.addEventListener(
      'mousemove',
      (event) => {
        this.mouseInput.x = (event.clientX / window.innerWidth) * 2 - 1
        this.mouseInput.y = -(event.clientY / window.innerHeight) * 2 + 1
      },
      false
    )

    // Touch move event
    document.addEventListener(
      'touchmove',
      (event) => {
        event.preventDefault() // Prevent the default scroll behavior
        this.mouseInput.x =
          (event.touches[0].clientX / window.innerWidth) * 2 - 1
        this.mouseInput.y =
          -(event.touches[0].clientY / window.innerHeight) * 2 + 1
      },
      { passive: true } // Option to prevent the default scroll behavior
    )

    this.mouseIsInside = true

    document.addEventListener('mouseenter', () => {
      this.mouseIsInside = true
    })

    document.addEventListener('mouseleave', () => {
      this.mouseIsInside = false
    })

    // Touch start and end events
    document.addEventListener('touchstart', () => {
      this.mouseIsInside = true
    })

    document.addEventListener('touchend', () => {
      this.mouseIsInside = false
    })

    // this.mouseInput = new BasicCharacterControllerMouseInput()
    this.stateMachine = new CharacterFSM(
      new BasicCharacterControllerProxy(this.animations)
    )

    this.loadModels()
  }

  loadModels() {
    const modelName = 'cat_compressed.glb' // Changed from 'cattexture.glb' to 'catmodel.glb'
    const isFBX = modelName.includes('fbx')
    // Set up the loading manager and loader
    const loadingManager = new THREE.LoadingManager()

    // Create a loader with the loading manager
    const dracoLoader = new DRACOLoader(loadingManager)
    dracoLoader.setDecoderConfig({ type: 'js' });
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
    dracoLoader.preload()
    const loader = new GLTFLoader(loadingManager);
    loader.setDRACOLoader(dracoLoader);
    let loadingScreen = document.getElementById('model-loader-container')
    let loadingText = document.getElementById('model-loader-text')
    // Show the loading screen when loading starts
    loadingManager.onStart = function (url, itemsLoaded, itemsTotal) {
      // Show your loading screen here
      // add text to the loading screen
      loadingText.innerHTML = 'Loading ' + url + '...'
    }

    // Update the loading screen with the progress
    loadingManager.onProgress = function (url, itemsLoaded, itemsTotal) {
      // Update your loading screen or progress bar here
      loadingText.innerHTML =
        'Loading ' +
        url +
        '<br>Loaded ' +
        itemsLoaded +
        ' of ' +
        itemsTotal +
        ' files...'
    }

    // Hide the loading screen when loading is finished
    loadingManager.onLoad = function () {
      // show the webgl container
      document.getElementById('model-loader-container').style.display = 'none'
    }
    loader.setPath(this.params.path)

    loader.load(modelName, (loaderObj) => {
      const model = loaderObj?.scale ? loaderObj : loaderObj.scene
      model.scale.setScalar(4.5)
      model.traverse((child) => {
        child.castShadow = true
        // Set the material of the model to a light gray color
        // child.material = new THREE.MeshBasicMaterial({ color: 0xfff }) // Light gray
      })

      this.target = model

      this.params.scene.add(model)

      this.mixer = new THREE.AnimationMixer(model)

      this.manager = new THREE.LoadingManager()
      this.manager.onLoad = () => {
        this.stateMachine.setState('idle')
      }

      const onLoadAnimation = (name, data) => {
        const clip = data.animations[0]
        const action = this.mixer.clipAction(clip)

        // this.animations = { ...this.animations, [name]: { clip, action } }
        this.animations[name] = {
          clip,
          action,
        }
      }

      const loaderWithManager = isFBX
        ? new FBXLoader(this.manager)
        : new GLTFLoader(this.manager)
      loaderWithManager.setDRACOLoader(dracoLoader);
      loaderWithManager.setPath(this.params.path)

      // all our animations are within one fbx, load them
      loaderWithManager.load(modelName, (data) => {
        console.log(data)
        for (const anim of data.animations) {
          const animName = anim.name
            .toLowerCase()
            .replace('fatcat_rig|fatcat_rig|', '')
            .split(' ')[0]
          onLoadAnimation(animName, { animations: [anim] })
        }
        hideLoader()
      })
    })
  }

  playAnimation(name) {
    if (this.currentAnimation === name || !this.animations[name]) return
    const currentAction = this.animations[name].action
    if (this.currentAnimation && this.animations[this.currentAnimation]) {
      const prevAction = this.animations[this.currentAnimation].action
      if (currentAction && prevAction) {
        currentAction.reset()
        // currentAction.setLoop(THREE.LoopRepeat, 1)
        currentAction.clampWhenFinished = true
        currentAction.crossFadeFrom(prevAction, 0.2, true)
        currentAction.play()
        this.currentAnimation = name
      }
    } else if (currentAction) {
      this.currentAnimation = name
      currentAction.play()
    }
  }

  playAndLoopbackAnimation(name) {
    if (this.currentAnimation === name || !this.animations[name]) return
    const currentAction = this.animations[name].action
    if (currentAction) {
      const mixer = currentAction.getMixer()
      mixer.addEventListener('finished', () => {
        this.playAnimation('idle')
      })

      if (this.currentAnimation && this.animations[this.currentAnimation]) {
        const prevAction = this.animations[this.currentAnimation].action
        if (prevAction) {
          currentAction.reset()
          currentAction.setLoop(THREE.LoopPingPong, 1)
          currentAction.clampWhenFinished = true
          currentAction.crossFadeFrom(prevAction, 0.2, true)
          currentAction.play()
          this.currentAnimation = name
        }
      } else {
        this.currentAnimation = name
        currentAction.setLoop(THREE.LoopPingPong, 1)
        currentAction.play()
      }
    }
  }

  playAnimationAndMove(name) {
    if (this.currentAnimation === name || !this.animations[name]) return
    const currentAction = this.animations[name].action
    if (currentAction) {
      const mixer = currentAction.getMixer()
      let originalPosition = this.target.position.clone() // Store the original position before animation
      mixer.addEventListener('finished', () => {
        let newPosition = currentAction.getRoot().position.clone() // Get the new animated position
        let deltaPosition = newPosition.sub(originalPosition) // Calculate the difference in position
        this.target.position.add(deltaPosition) // Add the difference to the original position
        this.playAnimation('idle')
      })

      if (this.currentAnimation && this.animations[this.currentAnimation]) {
        const prevAction = this.animations[this.currentAnimation].action
        if (prevAction) {
          currentAction.reset()
          currentAction.setLoop(THREE.LoopOnce, 1)
          currentAction.clampWhenFinished = true
          currentAction.crossFadeFrom(prevAction, 0.2, true)
          currentAction.play()
          this.currentAnimation = name
        }
      } else {
        this.currentAnimation = name
        currentAction.setLoop(THREE.LoopOnce, 1)
        currentAction.play()
      }
    }
  }

  /**
   * Apply movement to the character
   *
   * @param time - seconds
   */
  update(time) {
    if (!this.target) return

    this.stateMachine.update(time, this.input)
    if (!this.mouseIsInside) {
      // Stop the object or make it idle
      this.velocity.z = 0
      this.playAnimation('idle')
    }

    // Get a reference to our floor plane
    let floorPlane = this.params.scene.getObjectByName('Floor Plane')

    // Create a Raycaster
    let raycaster = new THREE.Raycaster()

    // Update the picking ray with the camera and mouse position
    raycaster.setFromCamera(this.mouseInput, this.params.camera)

    // calculate objects intersecting the picking ray
    let intersects = raycaster.intersectObjects([floorPlane])
    const controlObject = this.target
    if (intersects.length > 0) {
      // Determine target and direction
      let targetPosition = intersects[0].point
      let directionToMouse = new THREE.Vector3()
        .subVectors(targetPosition, this.target.position)
        .normalize()

      // Calculate the quaternion of the new direction
      let targetQuaternion = new THREE.Quaternion().setFromUnitVectors(
        new THREE.Vector3(0, 0, 1),
        directionToMouse
      )

      const controlObject = this.target
      let distance = this.target.position.distanceTo(targetPosition)

      let rotationSpeed = 0.1 // this is the current rotation speed
      let maxRotationSpeed = 0.15 // define your maximum rotation speed here

      // If the object is near the mouse, slow down and eventually stop
      if (distance < 10) {
        this.velocity.z *= 0.9 // Adjust this value for different deceleration rates
        // limit the rotation speed
        rotationSpeed = Math.min(rotationSpeed, maxRotationSpeed)
        controlObject.quaternion.slerp(targetQuaternion, rotationSpeed) // use the limited rotation speed
      } else if (distance < 5) {
        this.velocity.z *= 0.5 // Adjust this value for different deceleration rates
        // limit the rotation speed
        rotationSpeed = Math.min(rotationSpeed, maxRotationSpeed)
        controlObject.quaternion.slerp(targetQuaternion, rotationSpeed) // use the limited rotation speed
      } else if (distance === 0) {
        this.velocity.z = 0 // Reset the velocity to zero when it's small enough
      } else {
        // limit the rotation speed
        rotationSpeed = Math.min(rotationSpeed, maxRotationSpeed)

        controlObject.quaternion.slerp(targetQuaternion, rotationSpeed) // use the limited rotation speed

        const forward = new THREE.Vector3(0, 0, 1)
        forward.applyQuaternion(controlObject.quaternion)
        forward.normalize()

        // Calculate dot product. If it's positive, forward direction is towards the mouse.
        let dot = forward.dot(directionToMouse)

        // Only move forward if the forward direction is towards the mouse, or if the object is further away than the threshold.
        if (dot > 0 && distance > 10) {
          const velocity = this.velocity
          velocity.z += this.acceleration.z * time

          // clamp velocity
          if (velocity.z > this.acceleration.z * 2.5) {
            velocity.z = this.acceleration.z * 2.5
          }

          forward.multiplyScalar(velocity.z * time)
          let newPosition = controlObject.position.clone().add(forward)

          // Check if the new position is too close to any other MouseFollowCharacterControllers
          let tooClose = false
          for (let i = 0; i < this.collisionObjects.length; i++) {
            let otherController = this.collisionObjects[i]
            // Make sure we are not checking distance with ourself
            if (
              otherController !== this &&
              newPosition.distanceTo(otherController.position) < 5
            ) {
              // 5 is the minimum distance to other controllers
              tooClose = true // If it's too close, don't update the position and exit early
            }
          }
          if (!tooClose) controlObject.position.copy(newPosition)
          else this.velocity.z = 0 // If it's too close, stop moving
        }
      }
    } else {
      // slow it down if it's not on the floor
      this.velocity.z *= 0.75
    }
    // play animations based on acceleration
    if (Object.keys(this.animations).length === 0) return
    if (this.velocity.z > 30) {
      this.playAnimation('run')
    } else if (this.velocity.z > 0.1) {
      this.playAnimation('walk')
    } else {
      if (
        this.currentAnimation !== 'jump' &&
        this.currentAnimation !== 'roll'
      ) {
        this.playAnimation('idle')

        // randomly play idle animations (roll, jump, etc.) with super low chances
        // lets aim to play one every couple seconds or so
        if (Math.random() < 0.001) {
          this.playAndLoopbackAnimation('jump')
        } else if (Math.random() < 0.001) {
          this.playAndLoopbackAnimation('jump')
          //this.playAnimationAndMove("roll")
        }
      }
    }

    this._position.copy(controlObject.position)

    this.mixer?.update(time)
  }

  /**
   * Getter
   */
  get rotation() {
    if (!this.target) return new THREE.Quaternion()
    return this.target.quaternion
  }

  get position() {
    return this._position
  }
}

export default MouseFollowCharacterController
